<template>
    <section class="partnership my-5">
        <h2 class="partnership__title mt-lg-3 mb-3 mb-lg-5">{{ getPartnershipPageInfo('title') }}</h2>

        <p class="partnership__text col-lg-9 col-xl-7">
            {{ getPartnershipPageInfo('text') }}
        </p>

        <div class="partnership_cards d-flex flex-column justify-content-center flex-xl-row mt-5">
            <div class="d-flex flex-column">
                <div class="h-100">
                    <div class="d-flex flex-column flex-md-row h-100">
                        <div class="partnership_card partnership__partnership-agreement partnership-agreement partnership_card--primary col-12 col-md-5 p-3 mb-3 mb-lg-0 me-md-3 me-lg-0">
                            <div class="partnership-agreement__text">Соглашение о партнeрстве</div>
                            <div class="card-agreement__text mt-2 d-flex">
                                <div class="partnership-agreement__download me-2">
                                    <a :href="getAgreementFile">Скачать соглашение</a>
                                </div>
                                <div><img src="../../assets/views/partnership/partnership-arrow.svg" alt=""></div>
                            </div>
                        </div>

                        <div
                            class="partnership_card d-flex flex-column partnership_card--light col-12 col-md-7 ms-lg-3"
                            @click="goToPage('StrategicPartnership')"
                        >
                            <div class="d-flex partnership-scientific">
                                <div class="partnership-scientific__text ms-4 mt-3">
                                    <router-link :to="{ name: 'StrategicPartnership' }">
                                        Стратегическое партнерство
                                    </router-link>
                                </div>

                                <img
                                    class="d-block mt-auto partnership-educational__img ms-auto"
                                    src="../../assets/views/partnership/strategic-partnership1.svg"
                                    alt=""
                                >
                            </div>

                            <img
                                class="d-inline-block ms-auto partnership__strategic-img"
                                src="../../assets/views/partnership/strategic-partnership2.svg"
                                alt=""
                            >
                        </div>
                    </div>
                </div>

                <div
                    class="partnership_card partnership_card--light d-flex flex-column mt-3"
                    @click="goToPage('InnovativePartnership')"
                >
                    <div class="partnership-innovation__text ms-4 mt-3">
                        <router-link :to="{ name: 'InnovativePartnership' }">
                            Инновационно-коммуникационное партнерство
                        </router-link>
                    </div>
                    <img
                        class="d-block mt-auto partnership-innovation__img ms-auto"
                        src="../../assets/views/partnership/innovation-partnership.svg"
                        alt=""
                    >
                </div>
            </div>

            <div class="d-flex flex-column flex-md-row">
                <div
                    class="partnership_card partnership__educational-card partnership_card--light d-flex flex-column col-12 col-md-6 col-xl-7 ms-xl-4 mt-3 mt-xl-0 mt-xxl-0 me-4"
                    @click="goToPage('EducationalPartnership')"
                >
                    <div class="partnership-educational__text ms-4 my-3">
                        <router-link :to="{ name: 'EducationalPartnership' }">
                            Образовательное партнерство
                        </router-link>
                    </div>
                    <img
                        class="d-block mt-auto partnership__educational-img partnership-educational__img"
                        src="../../assets/views/partnership/educational-partnership1.svg"
                        alt=""
                    >
                </div>

                <div
                    class="partnership_card partnership-scientific partnership_card--light d-flex flex-column col-12 col-md-6 col-xl-5 h-100 mt-3 mt-lg-3 mt-xl-0"
                    @click="goToPage('ScientificPartnership')"
                >
                    <div class="partnership-scientific__text ms-4 mt-3">
                        <router-link :to="{ name: 'ScientificPartnership' }">
                            Научное партнерство
                        </router-link>
                    </div>
                    <img
                        class="d-block mt-auto ms-auto partnership-scientific__img"
                        src="../../assets/views/partnership/scientific-partnership.svg"
                        alt=""
                    >
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import {onMounted} from "vue";
import routerMixin from "@/mixins/routerMixin";

export default {
    name: "Partnership",

    setup() {
        const store = useStore()

        const { isMenuVisible, toggleMenu, goToPage } = routerMixin();

        store.dispatch('widgets/getPartnershipAgreementWidgets')

        const partnershipAgreementWidgets = computed(() => store.getters['widgets/partnershipAgreementWidgets'])

        const getAgreementFile = computed(() => {
            if (partnershipAgreementWidgets.value) {
                return `/${partnershipAgreementWidgets.value.agreement_file.split('/').slice(3).join('/')}`
            }

            return ''
        })

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        store.dispatch('pages/getPartnershipPage')
        const partnershipPage = computed(() => store.getters['pages/partnershipPage'])

        const getPartnershipPageInfo = (key) => {
            if (partnershipPage.value) {
                return partnershipPage.value[key]
            }

            return ''
        }

        return {
            getAgreementFile,
            getPartnershipPageInfo,
            goToPage
        }
    }
}
</script>
